<template>
  <div class="video" :class="{ active }">
    <!-- <div class="video-wrapper"> -->
    <LazyAtomsImage v-if="image" :image="image" class="video-image" />
    <video
      v-if="video"
      ref="videoElement"
      class="video-frame"
      :poster="poster"
      :src="video.url"
      preload="none"
      controls
      :style="{
        animationDelay: delay + 's',
      }"
    />
    <LazyAtomsButton
      v-if="active"
      :icon="{ name: 'x-mark' }"
      plain
      class="video-close"
      title="Close video"
      aria-label="Close video"
      @click="stop()"
      @keyup.esc="stop()"
    />
    <LazyAtomsButton
      v-if="!active"
      class="video-play"
      aria-label="Play video"
      plain
      @click="play()"
      @keyup.enter="play()"
    >
      <PlayIcon class="video-play-icon" />
    </LazyAtomsButton>
    <!-- </div> -->
  </div>
</template>

<script lang="ts" setup>
import PlayIcon from '~/assets/svg/play.svg'
import type { SanityImage } from '~/types/groq'
const props = defineProps({
  video: {
    type: Object,
    default: () => ({}),
  },
  io: { type: Object, default: () => ({}) },
  delay: { type: Number, default: 0 },
  image: Object as () => SanityImage,
})

const active = ref(false)
const videoElement = ref<HTMLVideoElement | null>()
const poster = props.image && getImageUrl(props.image, { width: 1200 })

function play() {
  if (active.value) return
  active.value = true

  setTimeout(() => {
    videoElement.value?.play()
  }, 500)
}

function stop() {
  if (videoElement.value) {
    videoElement.value.currentTime = 0
    videoElement.value.pause()
  }
  active.value = false
}

watchEffect(() => {
  handleEventListener<'keyup'>(active.value, 'keyup', (e) => {
    switch (e.code) {
      case 'Escape':
        stop()
        break
    }
  })
})
</script>

<style lang="scss">
.video {
  position: relative;
  display: flex;
  flex-flow: column;
  // width: 100vw;
  width: 96vw;
  height: 96 * 0.56980057vw;
  max-height: 75vh;
  align-self: center;
  justify-content: center;
  z-index: 10;
  // height: 0;
  // width: 60 * 1.775vh;
  // border-radius: var(--br-items-sm);
  // max-height: 54.1vw;
  // margin: $lg auto;
  margin: $lg -50%;
  // overflow: hidden;
  clip-path: inset(10% 10% round var(--br-items-sm));
  will-change: clip-path;
  transition: transition($transition, (transform, clip-path));

  animation: appearY 1s ease-out backwards paused;

  @include media('<tablet') {
    clip-path: inset(10% 10% round var(--br-items-lg));
  }

  &.active {
    clip-path: inset(0% 0% round var(--br-items-lg));

    .video-frame {
      opacity: 1;

      + .video-play {
        transform: translateX(-0.5rem) rotate(120deg);
        opacity: 0;
      }
    }
  }

  .video {
    // &-wrapper {
    //   position: relative;
    //   width: 100%;
    //   height: 100%;
    // }
    &-image,
    &-play,
    &-close,
    &-frame {
      position: absolute;
      // max-width: 60 * 1.775vh;
      // max-height: 60vh;
      inset: 0;
      width: 100%;
      height: 100%;
      transition: transition($transition, (transform, opacity));
    }

    &-image {
      inset: 10% 10%;
      width: 80%;
      height: 80%;
      z-index: 20;
      border-radius: var(--br-items-sm);

      @include media('<tablet') {
        border-radius: var(--br-items-lg);
      }
    }

    &-frame {
      // position: relative;
      z-index: 30;
      opacity: 0;
      object-fit: cover;
    }

    &-play,
    &-close {
      width: 4em;
      height: 4em;
      margin: auto;
      z-index: 50;
      color: var(--bg);

      &:hover,
      &:focus,
      &:active {
        color: var(--purple);
      }
    }

    &-play-icon {
      width: 100%;
      height: 100%;
    }

    &-close {
      width: 1em;
      height: 1em;
      font-size: 2em;
      inset: 1em 1em auto auto;
    }
  }

  &:hover,
  &:focus,
  &:active {
    .video {
      &-image {
        transform: scale(1.05);
      }
      &-play {
        transform: scale(1.15);
      }
    }
  }
  &:active {
    .video {
      &-image {
        transform: scale(1.025);
      }
      &-play {
        transform: scale(1.05);
      }
    }
  }
}
</style>
